const common = {
    getTxData(options) {
        const defaultOptions = {
            // nodeName: "SimpleMicroblog",
            metaIdTag: process.env.VUE_APP_MetaIdTag,
            // brfcId: "987654321",
            // accessToken: "token",
            // encrypt: 0,
            payCurrency: "bsv",
            payTo: [
                {
                    amount: 2000,
                    address: process.env.VUE_APP_PlatformAddress
                },
            ],
            // path: "/Protocols/SimpleMicroblog",
            dataType: "application/json",
            encoding: "UTF-8",
            // attachments: [
            //     {
            //         fileName: "PC0b3c7d089fa7d55720d6cf.png",
            //         fileType: "image/png",
            //         data: "89504ae426082",
            //         encrypt: 0,
            //     },
            // ],
            // data: JSON.stringify({ content: "这是一个测试内容", title: "测试标题" }),
            checkOnly: false,
            needConfirm: true
        }
        let data = Object.assign({}, defaultOptions, options)
        return data
    }
}
export default common